var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    [
      _c(
        "page-title",
        [
          _c(
            "template",
            { slot: "btn-inner" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.save()
                    }
                  }
                },
                [_vm._v(" 保存 ")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.$router.back()
                    }
                  }
                },
                [_vm._v(" 返回 ")]
              )
            ],
            1
          )
        ],
        2
      ),
      _c("div", { staticClass: "role-inner" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v(" " + _vm._s(_vm.$route.query.roleName) + "-设置人员 ")
        ]),
        _c("div", { staticClass: "role-header" }, [_vm._v(" 已选择员工： ")]),
        _c(
          "div",
          { staticClass: "role-tag" },
          _vm._l(_vm.selection, function(tag) {
            return _c(
              "el-tag",
              {
                key: tag.employee.employeeCode,
                attrs: { closable: "" },
                on: {
                  close: function($event) {
                    return _vm.handleClose(tag)
                  }
                }
              },
              [_vm._v(" " + _vm._s(tag.employee.name) + " ")]
            )
          }),
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "partition-area" },
        [
          _c(
            "search-inner",
            {
              attrs: { "search-id": 1, "search-form": _vm.searchForm },
              on: { "submit-search": _vm.searchData, "clear-search": _vm.reset }
            },
            [
              [
                _c(
                  "el-col",
                  { attrs: { span: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "用户名：" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入用户名" },
                          model: {
                            value: _vm.searchForm.accountLike,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "accountLike", $$v)
                            },
                            expression: "searchForm.accountLike"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "姓名：" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入姓名" },
                          model: {
                            value: _vm.searchForm.nameLike,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "nameLike", $$v)
                            },
                            expression: "searchForm.nameLike"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "手机号：" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入手机号" },
                          model: {
                            value: _vm.searchForm.mobileLike,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "mobileLike", $$v)
                            },
                            expression: "searchForm.mobileLike"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "状态：" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "全部", filterable: "" },
                            model: {
                              value: _vm.searchForm.state,
                              callback: function($$v) {
                                _vm.$set(_vm.searchForm, "state", $$v)
                              },
                              expression: "searchForm.state"
                            }
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "全部", value: "" }
                            }),
                            _c("el-option", {
                              attrs: { label: "启用", value: "20" }
                            }),
                            _c("el-option", {
                              attrs: { label: "禁用", value: "10" }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            ],
            2
          ),
          _c(
            "table-inner",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading.list,
                  expression: "loading.list"
                }
              ],
              attrs: {
                title: "筛选结果",
                "page-num": _vm.list.pageNum,
                "page-size": _vm.list.pageSize,
                pages: _vm.list.pages,
                "page-id": _vm.list.pageId
              },
              on: { changePageNum: _vm.changePageNum }
            },
            [
              _c(
                "template",
                { slot: "table-bd" },
                [
                  _c(
                    "el-table",
                    {
                      ref: "selectTable",
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.list.list },
                      on: { "selection-change": _vm.handleSelectionChange }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          selectable: _vm.setSelection,
                          "min-width": "50"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "employee.name",
                          label: "姓名",
                          align: "center",
                          formatter: _vm.utils.isEffective,
                          "show-overflow-tooltip": "",
                          "min-width": "150"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "状态",
                          align: "center",
                          "show-overflow-tooltip": "",
                          "min-width": "150"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm._.get(scope, "row.employee.status") === "20"
                                  ? _c("span", { staticClass: "success" }, [
                                      _vm._v("已启用")
                                    ])
                                  : _c("span", { staticClass: "danger" }, [
                                      _vm._v("已禁用")
                                    ])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "orgNameList",
                          label: "所属组织机构",
                          align: "center",
                          formatter: _vm.utils.isEffective,
                          "show-overflow-tooltip": "",
                          "min-width": "150"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "employee.mobile",
                          label: "手机号",
                          align: "center",
                          formatter: _vm.utils.isEffective,
                          "show-overflow-tooltip": "",
                          "min-width": "150"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "user.account",
                          label: "用户名",
                          align: "center",
                          formatter: _vm.utils.isEffective,
                          "show-overflow-tooltip": "",
                          "min-width": "150"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }